<template>
  <div class="main-container" v-if="movie">


<!--    <Breadcrumb :items="items" v-bind:title="movie.title" :style="{'background-image':'url(' + this.baseImageUrl + movie.backdrop_path + ')'}" />-->

    <div class="container py-5">
      <div class="row">
        <div class="col-4">
          <a :href="this.baseImageUrl + 'original' + movie.poster_path" data-fancybox>
            <img v-bind:src="this.baseImageUrl + 'w500' + movie.poster_path" alt="" class="img-fluid">
          </a>
        </div>
        <div class="col-8">
          <h1>{{ movie.title }}</h1>
          <p class="w-75 mt-4">{{ movie.overview }}</p>

          <a v-if="movie.homepage" :href="movie.homepage" target="_blank" class="btn btn-outline-success mr-3">Website</a>

          <a v-bind:href="'https://www.imdb.com/title/' + movie.imdb_id" target="_blank">
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/69/IMDB_Logo_2016.svg" width="75">
          </a>

          <p class="mt-4">Rates: {{ movie.vote_average }}/10</p>

          <Genre v-for="genre in movie.genres" :id="genre.id" :item="genre"></Genre>
<!--          <span class="badge bg-info text-white mr-1" v-for="genre in movie.genres" :id="genre.id" :item="genre">{{ genre.name }}</span>-->

          <div class="col-12 mx-auto mt-5">
            <div class="row">
              <MovieLogos v-for="company in movie.production_companies" :id="company.id" :item="company"></MovieLogos>
            </div>
          </div>

          <ul>
            <li v-for="genre in genres" :id="genre.id" :item="genre">{{ genre.name }}</li>
          </ul>

        </div>
      </div>
    </div>


    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import data from '../data/project.json'
import axios from "axios";
import router from "@/router";
import MovieLogos from "@/components/MovieLogos";
import Genre from "@/components/movies/Genre";
import { Fancybox } from "@fancyapps/ui";

export default {
  components: {
    MovieLogos,
    Genre
  },
  data() {
    return {
      data,
      movie: null,
      genres: null,
      id: this.$route.params.id,
      items: [
        {
          text: 'Home',
          to: "/"
        },
        {
          text: 'Movie Details',
          active: true
        }
      ]
    }
  },
  metaInfo: {
    title: 'Castro - Movie Details',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    }
  },
  mounted() {
    axios
        .get(this.apiUrl + this.id + this.apiEndPoint)
        .then(response => (this.movie = response.data))
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push('/404')
          }
        });
  }
}
</script>
<style scoped>
  @import "~@fancyapps/ui/dist/fancybox.css";
</style>
